.navbar.scrolled {
  background-color: rgba(63, 0, 50, 0.9) !important;
  transition: background-color 200ms linear;
}
  
.navbar.scrolled .nav-link {
  color:#555;
}

.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.button-style a {
  background-color:#ffffff;
  color:#AC0076 !important;
  border: none;
}

.dropdown-menu > a {
  font-weight: bold;
}

.mobi {
  display: none;
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .no-boarder div {
    border: none;
  }
}

@media only screen and (max-width: 991px) {
  .desktop {
    display: none;
  }

  .mobi {
    display: block;
  }

  .navbar {
    background-color: rgba(63, 0, 50, 0.9) !important;
  }

  .dropdown-item {
    color: #ffffff;
  }
}