.bg-color {
    background-color: #AC0076;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    margin: 0;
    padding: 0;
}

.bg-transparent-landing {
    background-color: transparent;
}

.landing-img {
    width: 90%;
    border: 20px solid #3F0032;
}

.full-screen {
    height: 100vh;
}

.button-style {
    background-color:#ffffff;
    color:#AC0076;
    border: none;
}

.button-style-2 {
    background-color: #3F0032;
    color:#ffffff;
    border: none;
}

.button-style-2:hover {
    color:#ffffff69;
}

.home-p {
    font-size: 24px;
    font-weight: 100;
}

#flash {
    color: #3F0032;
}

@media only screen and (max-width: 800px) {
    .bg-color {
        background-image: url("./resources/AFRONAUT_CROP.png");
        height: 100%;
        min-height: 100vh;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        margin: 0;
        padding: 0;
    }

    .landing-div {
        display: none;
    }

    .bg-transparent-landing {
        background-color: rgba(172, 0, 118, 0.7);
    }
  }