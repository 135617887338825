.contact_bg {
  background-image: url("./resources/CONTACT.png");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-height {
  height: 100%;
}

#flash-alt {
  color: #AC0076;
}

@media only screen and (max-width: 600px) {
  .contact_bg {
    height: 100%;
  }
}