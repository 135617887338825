.about-bg {
    background-image: url("./resources/ABOUT_HEADER.png");
    height: 40vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.white-color {
    color: #ffffff !important;
}

.purple-color {
    color: #AC0076;
}

.dark-purple-color {
    color: #3F0032;
}


.about-table {
    display: table; 
    height: 100%; 
    overflow: hidden;
}

.about-vertical {
    display: table-cell; 
    vertical-align: middle;
}

.vibes {
    background-image: url("./resources/EXPLORATION.png");
    height: 110vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.inherit-height {
    height: 100%
}

.cover_page {
    background-image: url("./resources/HOME_BACKGROUND.png");
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.asambe {
    background-image: url("./resources/ASAMBE_CSR.png");
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.support {
    background-image: url("./resources/OFF_WHITE.png");
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.partners {
    background-image: url("./resources/PARTNER_BG.png");
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.about-image-width {
    width: inherit;
}

.afronaut {
    width: 100%;
    max-width: 500px;
    max-height: 700px;
}

.sustain {
    width: 100%;
    max-height: 100vh;
}

@media only screen and (max-device-width: 1200px) {
    .vibes {
        background-image: url("./resources/VIBES_BG.png");
        height: 100%;
        width: 100%;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

    .afro {
        content:url("./resources/DOOR.png");
    }
  }