.landing {
  background-image: url("./resources/LANDING2.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.guided {
  background-image: url("./resources/GUIDED.png");
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.dark-bg {
  background-color: #3F0032;
}

.btn-dark-purple {
  background: #3F0032;
  color: white;
}

.btn-light-purple {
  background: #AC0076;
  color: white;
}

.light-purple-color {
  color: #AC0076;
}

.pattern-bg {
  background-image: url("./resources/PATTERN.png");
  height: 100%;
  width: 100%;
  background-color: #AC0076;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.show-mobile {
  display: none;
}

.stores {
  width: 30%;
  height: 100%;
}

.apple {
  text-align: left;
}

.google {
  text-align: right;
}

#map {
  width: 100%;
  height: 50vh;
  background-color: grey;
}

@media only screen and (max-width: 600px) {
  .landing {
    height: 100%;
    width: 100%;
  }

  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none;
  }

  .apple {
    text-align: center;
  }

  .google {
    text-align: center;
  }
  
}