.home_bg {
    background-image: url("./resources/HOME_BACKGROUND.png");
    height: 100%;
    min-height: 100vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    margin: 0;
    padding: 0;
}

.full-screen {
    height: 100vh;
}

.button-style {
    background-color:#ffffff;
    color:#AC0076;
    border: none;
}

.button-style-1 {
    background-color: #AC0076;
    color:#ffffff;
    border: none;
}

.button-style-2 {
    background-color: #3F0032;
    color:#ffffff;
    border: none;
}

.button-style-1:hover {
    color:#ffffff69;
}

.button-style-2:hover {
    color:#ffffff69;
}

.home-p {
    font-size: 24px;
    font-weight: 100;
}

#flash {
    color: #3F0032;
}