body {
  background-color: #FDFBD9;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
}

h3 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
}

p {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: lighter;
  font-size: 25px;
}

p b {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
}

a {
  font-family: 'Roboto', sans-serif;
}

.btn {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.btn-inherit {
    width: inherit;
}

#advanced {
    display: none;
}

.home_card {
    position: absolute;
    top: 25%;
}

.card_border {
    border: none;
}

.guide_card {
    opacity: 0.9;
}

.mobile-show {
  display: none;
}

#menu-padding {
  margin-right: 15px;
}

.button-style {
  background-color:#ffffff;
  color:#AC0076 !important;
  border: none;
}

.text-color {
  color:#AC0076;
}

::-webkit-input-placeholder {
  font-family: 'Exo 2';
}

:-ms-input-placeholder {
  font-family: 'Exo 2';
}

:-moz-placeholder {
  font-family: 'Exo 2';
}

::-moz-placeholder {
  font-family: 'Exo 2';
}

@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  .mobile-center {
    width: 100%;
    text-align: center;
  }

  .mobile-hide {
      display: none;
  }

  .mobile-show {
      display: block;
  }
}

.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}