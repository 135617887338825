.button-style-3 {
  background-color:#AC0076;
  color:#ffffff !important;
  border: none;
}

.auth_bg {
  background-image: url("./resources/AUTH.png");
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  margin: 0;
  padding: 0;
}

.padding_top {
  padding-top: 10%;
}