.footer-bg {
  background: #3F0032;
}

.footer-height {
  height: 100%;
}

.footer-bg a {
  font-family: 'Exo 2';
}

.footer-bg h3 {
  font-family: 'Exo 2';
}