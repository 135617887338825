.search {
  background-image: url("./resources/SEARCH.png");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.search-padding {
  padding-top: 7%;
  padding-bottom: 5%;
}

.checked {
  color: #3F0032;
}

.not-checked {
  color: lightgray;
}

.show-height {
  max-height: 700px;
}

.clamp-paragraph {
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 20px;
}

.cover-page {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.include-breaks {
  white-space: pre-line;
}

.pointer {
  cursor: pointer;
}

.purple-hover :hover {
  color: #3F0032;
  text-decoration: underline;
  text-decoration-color: #3F0032;
}

.loader {
  position: fixed;
  top: 8%;
  z-index: 200;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #AC0076;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 600px) {
  .search-padding {
    padding-top: 20%;
    padding-bottom: 10%;
  }
}