.logistic-landing {
    width: 85%;
}

.logistic-phone {
    width: 75%;
}

.logistic-bg {
    background-color: rgba(63, 0, 50, 1);
}

.logistic-bg-alt {
    background-color: #AC0076;
}

.color-alt {
    color: #AC0076;
}

.color-alt-1 {
    color: rgba(63, 0, 50, 1);
}

.color-white {
    color: #ffffff;
}

.logistic-card {
    border: 10px solid #AC0076;
    border-radius: 50px;
}

.slide-img {
    width: 70%;
}

.glow {
    box-shadow: 0px 0px 20px #AC0076;
}

.bg-transparent {
    background-color: transparent!important;
    border: none;
}

@media (max-width: 768px) {
    .row-logistics {
      display: flex;
      flex-direction: column-reverse;
    }
  }